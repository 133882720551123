@import './variables';

// same as shared but w/o `background-color`
.button {
  background: none;
  border: none;
  font-size: inherit;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  justify-content: center;
  border-radius: $borderRadus;
  color: white;
  cursor: pointer;
  margin-top: 16px 0px;
  align-items: center;

}
