@import './variables';

.main {
  width: 100%;
}

.scopes {
  width: 100%;
  max-height: 410px;
  overflow: auto;
}

.scopesBox {
  width: 100%;
  border: 1px solid #1616161a;
  padding: 10px 16px;
  margin-bottom: 24px;
  box-sizing: border-box;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.scopesHeader {
  font-size: $h4;
  font-weight: 500;
  margin-bottom: 4px;
}

.scopesSubheader {
  font-size: $p;
  color: #828282;
}

.bulletGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.iconGroup {
  padding: 4px;
  background: #f2f4f6;
  border-radius: 50%;
  margin-right: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconGroup img {
  width: 14px;
  height: 14px;
}
