@import './variables';

.closeIcon {
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
  z-index: 1;
}

.outerContainer {
  height: 100%;
}

.container {
  background-color: white;
  position: relative;
  height: 100vh;
  overflow: auto;
}

b,
strong {
  font-weight: 500;
}

@media only screen and (min-width: $mobileBreakPoint) {
  .outerContainer {
    position: relative;
    top: 0;
    left: 0;
    max-height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 4%;
    padding-bottom: 2%;
    box-sizing: border-box;
    overflow: auto;
  }

  .container {
    height: 950px;
    overflow: auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    width: 450px;
    border-radius: 10px;
    box-sizing: border-box;
  }
}
