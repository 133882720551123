@import './variables';

.container {
  position: relative;
  padding: 42px 24px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
}

.main {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
}

.provider {
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: $h4;
  font-weight: 500;
  border-top: 0.5px solid $colorLight;
  border-bottom: 0.5px solid $colorLight;

  cursor: pointer;
}

.provider:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.providerLogo {
  border-radius: 50%;
  margin-right: 16px;
  width: 35px;
  height: 35px;
}

.providers {
  height: 100%;
  max-height: calc(100% - 60px);
  overflow: auto;
}

.searchBox {
  margin: 0 16px 16px 16px;
  box-sizing: border-box;
  background-color: #f2f4f6;
  border-radius: $borderRadus;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  padding: 12px;
  width: 20px;
  height: 20px;
}

.input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 12px 12px 12px 0px;
  font-size: $h4;

  font-family: $inputFieldFont;
  color: $colorDark;
}

.input:focus {
  outline: none;
}

.emptySearchResults {
  padding: 16px;
}

.container {
  height: 100%;
}
