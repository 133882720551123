@import './variables';

.container {
  padding: 34px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}

.main {
  padding: 22px 0px;
  border-top: 1px solid $colorLight;
  border-bottom: 1px solid $colorLight;
}

.textHeader {
  font-size: $h4;
  font-weight: 500;
  margin-bottom: 6px;
  margin-top: 2px;
}

.subheader {
  font-size: $p;
  color: #828282;
}

.bulletGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.iconGroup {
  padding: 4px;
  background: #f2f4f6;
  border-radius: 50%;
  margin-right: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconGroup img {
  width: 14px;
  height: 14px;
}

.privacyPrompt {
  max-width: 360px;
  font-size: $p;
  text-align: center;
  color: #828282;
  margin-bottom: 16px;
}

.privacyPrompt a {
  color: $colorDark;
}
