@import './variables';

// $error-red: #f34040;
$error-red: #f04438; // red-500
$orange-500: #f79009;
$orange-800: #93370d;

$purple-50: #f9f5ff;
$purple-500: #9e77ed;
$purple-800: #53389e;

$yellow-50: #ffff00;
$yellow-500: #ffff9c;
$yellow-800: #e6e600;

$grey-50: #ebebeb;
$grey-500: #cccccc;
$grey-800: #a6a6a6;
$grey-900: #828282;

$black: #192533;

.error {
  border: 1px solid $error-red;
  color: $error-red;
}

.warning {
  border: 1px solid $purple-800;
  background-color: $purple-50;
  color: $orange-800;
}

.notice {
  border: 1px solid $black;
  background-color: $grey-50;
  color: $black;
}

.disclaimer {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 8px 0px;
  font-size: $p;
  color: $grey-900;
}

.container {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
  border-radius: 4px;
  padding: 10px 10px;
  font-size: $h4;

  display: flex;
  align-items: center;

}

.container img {
  margin-right: 12px;
}

.sessionKey {
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
  color: lighten($error-red, 10%);
  display: inline;
  align-items: center;
}

.code {
  margin-left: 1ch;
  align-items: center;
  display: inline;
}
