@import './variables';

// same as shared but w/o `background-color`
.button {
  background: none;
  border: none;
  font-size: inherit;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  justify-content: center;
  border-radius: $borderRadus;
  color: white;
  cursor: pointer;
  margin-top: 16px 0px;
}

.main {
  flex: auto;
  width: 110%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
}

.signInDisclaimer {
  display: flex;
  justify-content: center;
  margin: 8px 0px;
  font-size: $p;
  color: #828282;
}

.bulletGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  margin-bottom: 16px;
}

.iconGroup {
  padding: 4px;
  background: #f2f4f6;
  border-radius: 50%;
  margin-right: 16px;

  flex-shrink: 0;
  flex-grow: 0;

  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.textHeader {
  font-size: $p;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 2px;
}

.subheader {
  font-size: $p;
  color: #828282;
}

.textBlock {
  strong {
    font-weight: 500;
  }

  font-size: $h4;

  ul {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    list-style-position: inside;
    padding-inline-start: 8px;
  }
}
