@import './variables';

/* minor components */
.logos {
  height: 60px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.logos img {
  height: 60px;
  width: 101%;
}

.seperator {
  width: $seperatorWidth;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid $colorLight;
}

.largeLogo {
  margin-bottom: 12px;
  max-height: 32px;
}

.backIcon {
  position: absolute;
  top: 14px;
  left: 14px;
  cursor: pointer;
  width: 26px;
  height: 26px;
  z-index: 1;
}
/* end minor components */

/* form components */
.input {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  background: #f2f4f6;
  border-radius: $borderRadus;
  border: none;
  padding: 16px 16px;
  font-size: 16px;

  font-family: $inputFieldFont;
  color: $colorDark;
}

.input:hover,
.input:focus {
  background: white;
  outline: 1px solid $colorDark;
}

form {
  width: $seperatorWidth;
}

.button {
  background: none;
  border: none;
  font-size: inherit;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  justify-content: center;
  border-radius: $borderRadus;
  color: white;
  cursor: pointer;
  margin-top: 16px 0px;

  background-color: $colorDark;
}
/* end form components */

/* functional components */
.header {
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: $h5;

  margin-bottom: 24px;
  overflow-wrap: break-word;
}

.title {
  max-width: 240px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 16px;

  font-family: $titleFont;
}

.container {
  position: relative;
  padding: 46px 28px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
}

.footer{
  width: $seperatorWidth;
  display: flex;
  flex-direction: column;
}
/* end functional components */
