$colorDark: #161616;
$colorLight: #e8f1f5;

$mobileBreakPoint: 500px;

$h1: 62px;
$h2: 52px;
$h3: 20px;
$h5: 16px;
$h4: 14px;
$p: 11px;

$titleFont: Menlo;
$inputFieldFont: Menlo;

$seperatorWidth: 80%;

$borderRadus: 2px;

:export {
  colorDark: $colorDark;
  h1: $h1;
  h2: $h2;
  h3: $h3;
  h4: $h4;
  p: $p;
  titleFont: $titleFont;
  inputFieldFont: $inputFieldFont;
}
